<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :close-on-click="false"
    origin="right top"
  >
    <template #activator="{ attrs, on }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        icon
      >
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        Tilføj arbejde
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-autocomplete
            v-model="role"
            :loading="loading"
            :items="roles"
            :search-input.sync="searchInput"
            item-text="title"
            return-object
            cache-items
            clearable
            hide-no-data
            label="Rolle"
          />
          <v-text-field
            v-model="title"
            :disabled="!!role"
            label="Titel"
          />
          <v-text-field
            v-model="description"
            :disabled="!!role"
            label="Beskrivelse"
          />
          <v-text-field
            v-model="hours"
            type="number"
            label="Timer"
            :rules="[v => !!v || 'Udfyld antal']"
          />
          <v-text-field
            v-model="hourCostPrice"
            :disabled="!!role"
            type="number"
            label="Kostpris"
          />
          <v-text-field
            v-model="hourPrice"
            :disabled="!!role"
            type="number"
            label="Pris"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-btn text @click="close">
          Annuller
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn color="primary" @click="add">
          Tilføj
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'SpecificationSectionLaborCreate',
  props: ['id'],
  data() {
    return {
      menu: false,
      valid: false,
      creating: false,
      loading: false,
      roles: [],
      role: null,
      title: '',
      description: '',
      hours: null,
      hourCostPrice: null,
      hourPrice: null,
      searchInput: ''
    }
  },
  methods: {
    close() {
      this.menu = false;
      this.searchInput = null;
      this.title = '';
      this.description = '';
      this.hours = null;
      this.hourCostPrice = null;
      this.hourPrice = null;

      this.$refs.form.reset();
    },
    async add() {
      if (!this.$refs.form.validate()) return;

      const { title, description, hours, hourCostPrice, hourPrice } = this;

      const labor = {
        specificationSection_Id: this.id,
        customerRole_Id: this.role?.id ?? null,
        title,
        description,
        hours,
        hourCostPrice,
        hourPrice
      };

      try {
        this.creating = true;

        const response = await this.$AuthService.api.post('specification/section/labor', labor);

        if (!response.data.success) throw 'Error creating section part';

        this.$emit('create:success', response.data.model);
        this.$notifier.showSuccess({ message: 'Arbejde tilføjet' });
        this.close();
      } catch (error) {
        console.error(error);
        this.$notifier.showError({});
      } finally {
        this.creating = false;
      }
    }
  },
  async mounted() {
    try {
      this.loading = true;

      const response = await this.$AuthService.api.get('specification/customer-roles');

      if (!response.data.success) throw 'Kunne ikke hente roller';

      this.roles = response.data.model;
    } catch (error) {
      console.error(error);
      this.$notifier.showError({ message: error });
    } finally {
      this.loading = false;
    }
  },
  watch: {
    searchInput: {
      handler(value) {
        if (!!value) {
          this.search(value);
        }
      }
    },
    role(value) {
      this.title = value.title;
      this.description = value.description;
      this.hourCostPrice = value.defaultCostPrice;
      this.hourPrice = value.hourlyRate;
    }
  }
}
</script>