<template>
  <v-card>
    <v-card-title>
      Arbejde

      <v-spacer></v-spacer>

      <template v-if="section">
        <v-btn
          icon
          :disabled="!selected.length"
          @click="deleteLabors">
          <v-icon>
            mdi-delete-outline
          </v-icon>
        </v-btn>
        <SpecificationSectionLaborCreate
          v-bind="section"
          @create:success="onLaborCreateSuccess"
        />
      </template>
    </v-card-title>

    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="items"
      :show-select="!!section"
    >
      <template #[`item.hours`]="{ item }">
        <v-edit-dialog
          v-if="!$attrs.locked"
          :return-value.sync="item.hours"
          @save="$emit('update')"
        >
          {{ item.hours | formatNumberFixed2 }}

          <template #input>
            <v-text-field
              v-model.number="item.hours"
              :rules="[v => typeof v === 'number' || 'Skal være et tal']"
              label="Rediger"
              single-line
            />
          </template>
        </v-edit-dialog>
        <div v-else>
          {{ item.hours | formatNumberFixed2 }}
        </div>
      </template>
      <template #[`item.hourCostPrice`]="{ item }">
        {{ item.hourCostPrice | formatNumberFixed2 }}
      </template>
      <template #[`item.hourPrice`]="{ item }">
        {{ item.hourPrice | formatNumberFixed2 }}
      </template>
      <template #[`item.totalHours`]="{ item }">
        {{ item.totalHours | formatNumberFixed2 }}
      </template>
      <template #[`item.totalCostPrice`]="{ item }">
        {{ item.hourCostPrice * item.totalHours | formatNumberFixed2 }}
      </template>
      <template #[`item.totalPrice`]="{ item }">
        {{ item.hourPrice * item.totalHours | formatNumberFixed2 }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import SpecificationSectionLaborCreate from '@/components/specification/SpecificationSectionLaborCreate.vue';

export default {
  name: 'SpecificationLaborTable',
  components: {
    SpecificationSectionLaborCreate
  },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    section: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      selected: []
    }
  },
  computed: {
    headers() {
      const items = [
        { text: 'Type', value: 'title', width: '20%' },
        { text: 'Beskrivelse', value: 'description', width: '40%' },
        { text: 'Antal', value: 'hours', align: 'end' },
        { text: 'Kost pr. enhed', value: 'hourCostPrice', align: 'end' },
        { text: 'Salg pr. enhed', value: 'hourPrice', align: 'end' },
        { text: 'Total kost', value: 'totalCostPrice', align: 'end' },
        { text: 'Total salg', value: 'totalPrice', align: 'end' }
      ];

      if (!!this.section) {
        items.splice(5, 0, { text: 'Total antal', value: 'totalHours', align: 'end' });
      }

      return items;
    }
  },
  methods: {
    onLaborCreateSuccess(part) {
      this.section.labors.push(part);
    },
    async deleteLabors() {
      const ids = this.selected.map(x => x.id);

      if (!ids.length) return;

      try {
        this.deleting = true;

        // Create promises
        const promises = this.selected.map(x => {
          return new Promise((resolve, reject) => {
            this.$AuthService.api.delete(`specification/section/labor/${x.id}`)
            .then(() => {
              const index = this.section.labors.findIndex(labor => labor.id === x.id);
              this.section.labors.splice(index, 1);
              resolve();
            })
            .catch(error => { reject(error) });
          });
        });

        await Promise.all(promises);
        
        this.$notifier.showSuccess({ message: 'Arbejde slettet' });
      } catch (error) {
        console.error(error);
        this.$notifier.showError({ message: 'En eller flere arbejder kunne ikke slettes' });
      } finally {
        this.deleting = false;
      }
    }
  }
}
</script>